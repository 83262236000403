<template>
  <div>
    <h5 class="my-4 mx-5 text-center">{{ $t('auth.changePassword') }}</h5>
    <form
      @submit="onSubmit"
      ref="form"
      class="ui form w-100 text-center align-items-center justify-content-center auth-form">

      <p>{{ $t('auth.closeScreen') }}</p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'MobilePasswordSetted',
  data () {
    return { }
  }
}
</script>

<style scoped>
  p {
    color:gray;
    font-size: 1em;
  }
  h5 {
    font-size: 1.5em;
  }
</style>
